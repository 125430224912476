var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './common/theme';
import { Icon } from './Icon';
export var Sidebar = function (_a) {
    var _b = _a.structure, structure = _b === void 0 ? [] : _b, _c = _a.pinned, pinned = _c === void 0 ? [] : _c, selected = _a.selected, _d = _a.color, color = _d === void 0 ? 'primary100' : _d, _e = _a.selectedColor, selectedColor = _e === void 0 ? 'primary50' : _e, logo = _a.logo, logoStyles = _a.logoStyles, _f = _a.onSelect, onSelect = _f === void 0 ? function (s) { } : _f, _g = _a.stickBottom, stickBottom = _g === void 0 ? false : _g, _h = _a.compact, compact = _h === void 0 ? false : _h, version = _a.version, _j = _a.versionColor, versionColor = _j === void 0 ? 'gray100' : _j, style = _a.style, className = _a.className;
    var theme = useContext(ThemeContext);
    var _k = useState(null), openSection = _k[0], setOpenSection = _k[1];
    return (React.createElement(StyledSidebar, { color: theme[color], style: style, className: className },
        React.createElement("div", { style: { width: '100%', overflow: 'auto' } },
            logo && React.createElement(Logo, { src: logo, alt: "Logo", style: logoStyles }),
            structure.map(function (row) {
                var _a;
                return (React.createElement(Row, __assign({ key: row.name }, row, { compact: compact, size: "standard", color: theme['white'], open: openSection === row.name, onSelect: onSelect, onOpen: function () { return setOpenSection(function (os) { return (os === row.name ? null : row.name); }); }, backgroundColor: selected === row.name || ((_a = row.children) === null || _a === void 0 ? void 0 : _a.map(function (c) { return c.name; }).includes(selected || ''))
                        ? theme[selectedColor]
                        : theme[color], selected: selected })));
            })),
        stickBottom && React.createElement("div", { style: { flexGrow: 1 } }),
        React.createElement(BottomContainer, { color: theme[color], stick: stickBottom },
            pinned.map(function (row) { return (React.createElement(Row, __assign({ key: row.name }, row, { size: "standard", color: theme['white'], onSelect: onSelect, selected: selected, compact: compact, backgroundColor: selected === row.name ? theme[selectedColor] : theme[color] }))); }),
            version && (React.createElement(Row, { name: "version", size: "small", label: version, compact: compact, showArrow: false, color: theme[versionColor], backgroundColor: theme[color], onSelect: function () { }, selected: selected })))));
};
var Row = function (_a) {
    var name = _a.name, icon = _a.icon, label = _a.label, _b = _a.open, open = _b === void 0 ? false : _b, _c = _a.children, children = _c === void 0 ? [] : _c, showArrow = _a.showArrow, color = _a.color, backgroundColor = _a.backgroundColor, selected = _a.selected, onSelect = _a.onSelect, _d = _a.onOpen, onOpen = _d === void 0 ? function () { } : _d, size = _a.size, _e = _a.child, child = _e === void 0 ? false : _e, _f = _a.greyedOut, greyedOut = _f === void 0 ? false : _f, _g = _a.padBottom, padBottom = _g === void 0 ? false : _g, _h = _a.compact, compact = _h === void 0 ? false : _h;
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
        React.createElement(RowStyles, { key: name, onClick: function (ev) {
                ev.preventDefault();
                ev.stopPropagation();
                if (children.length > 0) {
                    onOpen();
                }
                else {
                    onSelect(name);
                }
            }, color: greyedOut ? color + "BF" : color, backgroundColor: backgroundColor, size: size, child: child, padBottom: padBottom },
            React.createElement(LeftIcon, null, icon ? (React.createElement(Icon, { name: icon, size: "18px", style: { margin: 0 } })) : (React.createElement("div", { style: { width: '18px', height: '18px', color: 'transparent' } }))),
            !compact && (React.createElement(React.Fragment, null,
                React.createElement("div", null, label),
                React.createElement(RightArrow, { mustRotate: open }, showArrow !== false && React.createElement(Icon, { name: "angle-right", size: "24px", style: { margin: 0 } }))))),
        React.createElement(ChildAnimatedPane, { open: open, childNumber: children.length }, children.map(function (child, i) { return (React.createElement(Row, __assign({ key: child.name }, child, { color: color, size: size, backgroundColor: backgroundColor, onSelect: onSelect, selected: selected }, { child: true, greyedOut: selected !== child.name, padBottom: i === children.length - 1 }))); }))));
};
var StyledSidebar = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: calc(100% - 20px);\n  height: 100%;\n  background-color: ", ";\n  margin: 0;\n  border: none;\n  padding-left: 10px;\n  padding-right: 10px;\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: center;\n  justify-content: flex-start;\n"], ["\n  width: calc(100% - 20px);\n  height: 100%;\n  background-color: ", ";\n  margin: 0;\n  border: none;\n  padding-left: 10px;\n  padding-right: 10px;\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: center;\n  justify-content: flex-start;\n"])), function (props) { return props.color; });
var textStyles = {
    standard: { size: 13, weight: 600, mt: 3 },
    small: { size: 10, weight: 400, mt: 0 },
};
var RowStyles = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Open Sans', sans-serif;\n  font-size: ", "px;\n  font-weight: ", ";\n  margin-top: ", "px;\n  padding-left: ", "px;\n  padding-top: 13px;\n  padding-bottom: ", "px;\n  outline: none;\n  border: none;\n  color: ", ";\n  background-color: ", ";\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  transition: background-color 0.3s ease;\n  z-index: ", ";\n  cursor: pointer;\n"], ["\n  font-family: 'Open Sans', sans-serif;\n  font-size: ", "px;\n  font-weight: ", ";\n  margin-top: ", "px;\n  padding-left: ", "px;\n  padding-top: 13px;\n  padding-bottom: ", "px;\n  outline: none;\n  border: none;\n  color: ", ";\n  background-color: ", ";\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  transition: background-color 0.3s ease;\n  z-index: ", ";\n  cursor: pointer;\n"])), function (props) { return textStyles[props.size].size; }, function (props) { return textStyles[props.size].weight; }, function (props) { return (props.child ? 0 : textStyles[props.size].mt); }, function (props) { return (props.child ? 20 : 0); }, function (props) { return (props.padBottom ? 23 : 13); }, function (props) { return props.color; }, function (props) { return props.backgroundColor; }, function (props) { return (props.child ? 0 : 1); });
var Logo = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: calc(100% - 20px);\n  margin-top: 70px;\n  margin-bottom: 80px;\n"], ["\n  width: calc(100% - 20px);\n  margin-top: 70px;\n  margin-bottom: 80px;\n"])));
var LeftIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-right: 15px;\n  margin-left: 20px;\n  margin-top: 3px;\n"], ["\n  margin-right: 15px;\n  margin-left: 20px;\n  margin-top: 3px;\n"])));
var RightArrow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: auto;\n  margin-right: 20px;\n  margin-top: 3px;\n  transform: ", ";\n  transition: transform 0.1s ease-out;\n"], ["\n  margin-left: auto;\n  margin-right: 20px;\n  margin-top: 3px;\n  transform: ", ";\n  transition: transform 0.1s ease-out;\n"])), function (props) { return (props.mustRotate ? 'rotate(90deg)' : 'none'); });
var ChildAnimatedPane = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  visibility: ", ";\n  opacity: ", ";\n  height: ", ";\n  max-height: ", ";\n  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;\n  flex: ", ";\n  overflow: hidden;\n"], ["\n  visibility: ", ";\n  opacity: ", ";\n  height: ", ";\n  max-height: ", ";\n  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;\n  flex: ", ";\n  overflow: hidden;\n"])), function (props) { return (props.open ? 'visible' : 'hidden'); }, function (props) { return (props.open ? '1' : '0'); }, function (props) { return (props.open ? 'inherit' : '0'); }, function (props) { return (props.open ? props.childNumber * 50 + 40 + "px" : '0'); }, function (props) { return (props.open ? 1 : 0); });
var BottomContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  padding-bottom: 20px;\n  ", "\n  background-color: ", ";\n"], ["\n  width: 100%;\n  padding-bottom: 20px;\n  ",
    "\n  background-color: ", ";\n"])), function (props) {
    return props.stick
        ? "\n  position: sticky;\n  bottom: 0;\n  left: 0;\n  z-index: 10;\n  "
        : "\n  margin-top: auto;\n  ";
}, function (props) { return props.color; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
