import React from 'react'
import styled from 'styled-components'

import { api } from '../../data/api'
import { CustomDataGrid } from '../../components/CustomDataGrid'
import { Column, Lookup } from 'devextreme-react/data-grid'
import { SelectBox } from 'devextreme-react'
import { dateFormatter, durationFormatter, ellipse } from '../../utils/formatters'
import { EditDuration } from '../../utils/durationEditor'

export const statuses = [
  {
    id: 'active',
    name: 'Commessa aperta',
  },
  {
    id: 'pending',
    name: 'Commessa sospesa',
  },
  {
    id: 'closed',
    name: 'Commessa chiusa',
  },
]

export const OrdersPage = () => (
  <Container>
    <CustomDataGrid
      className="orders-list"
      exportName="Commesse"
      remoteOperations={{ filtering: true, paging: true, sorting: true, summary: true }}
      onLoad={api.getPaginatedOrders}
      onUpdate={api.updateOrder}
      onEditorPreparing={(e) => {
        if (e.dataField === 'description' && e.parentType === 'dataRow') {
          const defaultValueChangeHandler = e.editorOptions.onValueChanged
          e.editorName = 'dxTextArea'
          e.editorOptions.height = 200
          e.editorOptions.onValueChanged = function (args: any) {
            defaultValueChangeHandler(args)
          }
        }
      }}
      customToolbarButtons={[
        {
          text: 'Export CSV',
          onClick: async () => {
            window.open(
              (
                await api.exportOrders({
                  customer: 'Cliente',
                  code: 'Codice',
                  description: 'Descrizione',
                  budget_minutes: 'Ore a Budget',
                  total_logged_minutes: 'Totale Ore Loggate',
                  status: 'Stato',
                  type: 'Tipo',
                  ddt: 'Nota Interna',
                  delivery_date: 'Data Consegna',
                  created_at: 'Data Creazione',
                })
              ).url,
              '_blank',
              'noopener,noreferrer'
            )
          },
        },
      ]}
    >
      <Column dataField="customer" caption="Cliente" dataType="string" />
      <Column dataField="code" caption="Codice" dataType="string" />
      <Column
        dataField="description"
        caption="Descrizione"
        dataType="string"
        format={ellipse}
        width="20vw"
      />
      <Column
        dataField="budget_minutes"
        caption="Budget ore"
        dataType="string"
        editorOptions={{ width: 200 }}
        format={durationFormatter}
        editCellComponent={EditDuration}
      />
      <Column
        dataField="machine_logged_minutes"
        caption="Ore totali Macchina"
        dataType="string"
        editorOptions={{ width: 200 }}
        format={durationFormatter}
        allowEditing={false}
        allowSorting={false}
        editCellComponent={EditDuration}
      />
      <Column
        dataField="internal_logged_minutes"
        caption="Ore totali Interni"
        dataType="string"
        editorOptions={{ width: 200 }}
        format={durationFormatter}
        allowEditing={false}
        allowSorting={false}
        editCellComponent={EditDuration}
      />
      <Column
        dataField="external_logged_minutes"
        caption="Ore totali Esterni"
        dataType="string"
        editorOptions={{ width: 200 }}
        format={durationFormatter}
        allowEditing={false}
        allowSorting={false}
        editCellComponent={EditDuration}
      />
      <Column
        dataField="total_logged_minutes"
        caption="Ore totali loggate"
        dataType="string"
        allowEditing={false}
        allowSorting={false}
        format={durationFormatter}
      />
      <Column
        dataField="delivery_date"
        caption="Data di consegna"
        dataType="date"
        format={dateFormatter}
      />
      <Column dataField="ddt" caption="DDT" dataType="string" />
      <Column
        dataField="status"
        caption="Stato"
        dataType="string"
        editCellRender={(cell) => (
          <SelectBox
            defaultValue={cell.value}
            {...cell.column.lookup}
            itemRender={(data) => (data ? data.name : '(Tutti)')}
            onValueChanged={({ value }) => cell.setValue(value)}
          />
        )}
      >
        <Lookup dataSource={statuses} displayExpr="name" valueExpr="id" />
      </Column>
    </CustomDataGrid>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  * > textarea {
    box-sizing: border-box; /* fit parent width */
    height: 200px !important;
    line-height: 1.2 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`
