import React, { useState } from 'react'
import styled from 'styled-components'
import { SelectBox } from 'devextreme-react'
import { Column, Lookup, Button } from 'devextreme-react/data-grid'
import 'jspdf-autotable'

import { api } from '../../data/api'
import { CustomDataGrid } from '../../components/CustomDataGrid'
import { PasswordModal } from './components/PasswordModal'

export const tags = [
  {
    id: 'user',
    name: 'Utente',
  },
  {
    id: 'admin',
    name: 'Admin',
  },
  {
    id: 'super_admin',
    name: 'Super Admin',
  },
]

export const UsersPage = () => {
  const [passwordUpdateUser, setPasswordUpdateUser] = useState<string>();
  return (
    <Container>
      <PasswordModal
        open={passwordUpdateUser ? true : false}
        onClose={() => {
          setPasswordUpdateUser(undefined);
        }}
        onUpdatePassword={async function (newPassword: string): Promise<void> {
          if (passwordUpdateUser) {
            await api.updateUserPassword(passwordUpdateUser!!, newPassword);
            setPasswordUpdateUser(undefined);
          }
        }} />
      <CustomDataGrid
        exportName="Utenti"
        onLoad={api.getUsers}
        onRemove={api.deleteUser}
        onUpdate={api.updateUser}
      >
        <Column dataField="email" dataType="string" allowEditing={false} />
        <Column dataField="first_name" caption="Nome" dataType="string" />
        <Column dataField="last_name" caption="Cognome" dataType="string" />
        <Column dataField="tax_code" caption="Codice Fiscale" dataType="string" />
        <Column dataField="phone_number" caption="Numero di telefono" dataType="string" />
        <Column
          dataField="role"
          caption="Ruolo"
          dataType="string"
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              {...cell.column.lookup}
              itemRender={(data) => (data ? data.name : '(Tutti)')}
              onValueChanged={({ value }) => cell.setValue(value)}
            />
          )}
        >
          <Lookup dataSource={tags} displayExpr="name" valueExpr="id" />
        </Column>
        <Column dataField="blocked" caption="Bloccato" dataType="boolean" />
        <Column
          dataField="disable_notifications"
          caption="Notifiche disabilitate"
          dataType="boolean"
        />
        <Column dataField="active" caption="Attivo" dataType="boolean" />
        <Column dataField="external" caption="Esterno" dataType="boolean" />
        <Column type="buttons">
          <Button name="delete" />
          <Button
            text="My Command"
            hint="My Command"
            icon="edit"
            onClick={({ row: { data: { id } } }: { row: { data: { id: string } } }) => {
              setPasswordUpdateUser(id);
            }}
          />
        </Column>
      </CustomDataGrid>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`
