import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import {
  Button,
  Input,
  NumericInput,
  Divider,
  SectionTitle,
  Radios,
  DateTimePicker,
} from '@duckma/react-ds'
import _ from 'lodash'
import it from 'date-fns/locale/it'

import { useRequest } from '../../hooks/useRequest'
import { api, CreateOrder, UpdateOrder } from '../../data/api'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { ControlledForm, ControlledField } from '../../components/ControlledForm'
import { requiredValidator } from '../../utils/validators'
import { OrderStatus } from '../../data/models'
import { AggregatedLogsSection } from './components/AggregatedLogsSection'
import { durationFormatter } from '../../utils/formatters'

const radioChoices = [
  { key: 'active', label: 'Aperta' },
  { key: 'pending', label: 'Sospesa' },
  { key: 'closed', label: 'Chiusa' },
]

export const OrderDetail = () => {
  const { id } = useParams<{ id: string }>()
  const createMode = !id
  const [date, setDate] = useState<Date | null>(null)
  const [getOrder, { status, data }] = useRequest(api.getOrder)
  const [createOrder, { status: createStatus }] = useRequest(api.createOrder)
  const [updateOrder, { status: updateStatus }] = useRequest(api.updateOrder)
  const [values, setValues] = useState<Partial<UpdateOrder & { budget_hours: number }>>({
    budget_minutes: 0,
    budget_hours: 0,
  })
  const [valid, setValid] = useState(true)
  const [request, { data: csvData }] = useRequest(api.getOrderCSV)
  useEffect(() => {
    if (!createMode && id && status === 'idle') {
      getOrder({ id })
    }
  }, [createMode, status, getOrder, id])

  useSuccessToast(createStatus, 'Commessa creata con successo', '/orders')
  useSuccessToast(updateStatus, 'Commessa modificata con successo')

  useEffect(() => {
    if (data) {
      setValues((values) => ({
        ...values,
        budget_hours: Math.floor(data.budget_minutes / 60),
        status: data.status,
      }))
      setDate(data.delivery_date)
    }
  }, [data])

  useEffect(() => {
    if (csvData) {
      const blob = new Blob([csvData], { type: 'text/plain;charset=utf-8' })
      const filename = `export-${
        data?.customer + '-' + data?.description + '(' + data?.code + ')'
      }.csv`
      saveAs(blob, filename)
    }
  }, [csvData, data])

  const initialValues = useMemo(() => {
    if (createMode) {
      return { customer: '', code: '', description: '' }
    }
    return data ? _.pick(data, ['code', 'customer', 'description']) : null
  }, [createMode, data])

  const onChangeInternal = useCallback((values, valid) => {
    setValues((v) => ({ ...v, ...values }))
    setValid(valid)
  }, [])

  return (
    <Container>
      <FormContainer
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault()
          const budget_minutes = (values?.budget_hours || 0) * 60
          if (createMode) {
            createOrder({
              ...values,
              delivery_date: date,
              budget_minutes,
              budget_hours: undefined,
            } as NonNullable<CreateOrder>)
          } else {
            updateOrder({
              id: id!,
              body: { ...values, budget_minutes, budget_hours: undefined } as NonNullable<
                UpdateOrder
              >,
            })
          }
        }}
      >
        <FieldsContainer>
          <ControlledForm initialValues={initialValues} onChange={onChangeInternal}>
            <ControlledField
              name="codice"
              fieldName="code"
              validator={createMode ? undefined : requiredValidator()}
              disabled={!createMode}
            />
            <ControlledField
              name="cliente"
              fieldName="customer"
              validator={requiredValidator()}
              disabled={!createMode && data?.type !== 'business'}
            />
            <DateRow>
              <DateTimePicker
                name="data-di-consegna"
                locale={it}
                selected={date}
                onChange={setDate}
                placeholderText="Seleziona la data di consegna"
                disabled={!createMode && data?.type !== 'business'}
              />
              <ControlledField
                name="ddt"
                fieldName="ddt"
                label="DDT"
                placeholder="DDT"
                disabled={!createMode && data?.type !== 'business'}
              />
            </DateRow>
            <ControlledField
              name="descrizione"
              fieldName="description"
              rows={5}
              disabled={!createMode && data?.type !== 'business'}
            />
          </ControlledForm>
          <HoursContainer colNumber={data ? 2 : 1}>
            {data && (
              <Input
                name="tempo-totale-registrato"
                type="text"
                disabled
                value={durationFormatter(data.total_logged_minutes)}
              />
            )}
            {(createMode || data?.type === 'business') && (
              <NumericInput
                name="ore-totali-preventivate"
                value={values.budget_hours}
                onChange={(budget_hours) =>
                  setValues((v) => ({ ...v, budget_hours: Math.max(budget_hours, 0) }))
                }
              />
            )}
          </HoursContainer>
        </FieldsContainer>
        {!createMode && (
          <>
            <SectionDivider />
            <SectionTitle text="Stato" color="black" style={{ margin: '20px 0' }} />
            <Radios
              choices={radioChoices}
              value={values.status || 'undefined'}
              left
              disabled={data?.type !== 'business'}
              onChange={(status) => setValues((v) => ({ ...v, status: status as OrderStatus }))}
            />
          </>
        )}
        {id && (
          <>
            <SectionDivider style={{ marginTop: '20px' }} />
            <SectionTitle text="Log utenti" color="black" style={{ margin: '20px 0' }} />
            <AggregatedLogsSection id={id} />
            <Button
              size="small"
              text="Export CSV"
              type="button"
              onClick={() => {
                request({ order: id })
              }}
              outline
              radius={4}
              style={{ width: '10vmax', backgroundColor: 'transparent' }}
            />
          </>
        )}
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            disabled={!valid}
            loading={status === 'loading'}
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 20px;
  }
`

const SectionDivider = styled(Divider)`
  margin-left: -54px;
  margin-right: -54px;
`

const HoursContainer = styled.div<{ colNumber: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.colNumber}, 1fr);
  grid-gap: 5vw;
`
const DateRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr;
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    > :nth-child(1) {
      grid-column: 1 / 3;
    }
  }
  grid-column-gap: 20px;
  > :nth-child(1) {
    height: 80px;
  }
`
