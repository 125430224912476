import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, Button, Input } from '@duckma/react-ds'

export const PasswordModal: React.FC<{
  open: boolean
  onUpdatePassword: (newPassword: string) => void
  onClose?: () => void
}> = ({ open, onUpdatePassword, onClose = () => {} }) => {
  const [newPassword, setNewPassword] = useState<string>()
  return (
    <StyledModal open={open} onClose={onClose} title="Imposta Password">
      <Content>
        <div style={{ display: 'flex', width: 'calc(100% - 70px)' }}>
          <Input name="new_password" onChange={setNewPassword} />
        </div>
        <div style={{ width: '100%', display: 'flex', flexGrow: 0 }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            onClick={() => {
              newPassword ? onUpdatePassword(newPassword) : onClose()
            }}
            style={{ flexGrow: 0, flexBasis: '20%', margin: '35px' }}
          />
        </div>
      </Content>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  position: fixed;
  top: 35vh;
  left: 30vw;
  right: 30vw;
  bottom: 35vh;
  outline: none;
  border-radius: 10px;
  background-color: white;
  > * {
    background-color: white;
    z-index: 2;
    border-radius: 10px;
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  height: calc(30vh - 64px);
  > * {
    width: calc(100% - 100px - 50px);
    border-radius: 10px;
  }
  > :first-child {
    flex-grow: 1;
  }
`
