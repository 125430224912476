import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Sidebar, Button } from '@duckma/react-ds'

import Logo from '../images/logo-light.svg'
import { storage } from '../data/storage'
import { useLocation, useHistory } from 'react-router-dom'
import { api } from '../data/api'
import { Header } from './Header'
import { User } from '../data/models'
import { version } from '../../package.json'
import Media from 'react-media'

export const SessionContext = React.createContext<{
  user?: User
  mustReload: () => void
  flagDirty: () => void
  resetDirty: () => void
  toggleSidebarOpen: () => void
}>({ mustReload: () => {}, flagDirty: () => {}, resetDirty: () => {}, toggleSidebarOpen: () => {} })

export const MainLayout: React.FC<{
  title?: string
  backLink?: { to: string; text: string }
  headerButton?: { text: string; to: string }
  headerOutlineButton?: { text: string; to: string }
  hideHeader?: boolean
}> = ({ children, title = '', backLink, headerButton, headerOutlineButton, hideHeader }) => {
  const location = useLocation()
  const history = useHistory()
  // If a form is dirty (with unsaved user work on it) the page sets dirty to true.
  // If the user then tries to go back, a popup is shown
  const [dirty, setDirty] = useState(false)

  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const toggleSidebarOpen = useCallback(() => setSidebarOpen((open) => !open), [])

  const [user, setUser] = useState(storage.getUser())
  const mustReload = useCallback(() => setUser(storage.getUser()), [])
  const flagDirty = useCallback(() => {
    window.onbeforeunload = () => ''
    setDirty(true)
  }, [])
  const resetDirty = useCallback(() => {
    window.onbeforeunload = () => null
    setDirty(false)
  }, [])

  return (
    <Grid>
      <SessionContext.Provider
        value={{
          mustReload,
          flagDirty,
          resetDirty,
          toggleSidebarOpen,
          user,
        }}
      >
        <Media queries={{ small: 'screen and (max-width: 600px)' }}>
          {(matches) => (
            <>
              {(!matches.small || isSidebarOpen) && (
                <SidebarContainer>
                  <Sidebar
                    structure={[
                      {
                        name: 'dashboard',
                        label: 'Dashboard',
                        icon: 'dashboard',
                      },
                      {
                        name: 'users',
                        label: 'Utenti',
                        icon: 'user-alt',
                      },
                      {
                        name: 'orders',
                        label: 'Commesse',
                        icon: 'folder',
                      },
                      {
                        name: 'logs',
                        label: 'Log ore uomo',
                        icon: 'clock',
                      },
                      {
                        name: 'machines',
                        label: 'Macchine',
                        icon: 'gear',
                      },
                      {
                        name: 'machinelogs',
                        label: 'Log ore macchina',
                        icon: 'poll',
                      },
                      {
                        name: 'calendar-section',
                        label: 'Calendario',
                        icon: 'calendar',
                        children: [
                          {
                            name: 'events',
                            label: 'Calendario globale',
                            showArrow: false,
                          },
                          // {
                          //   name: 'event-types',
                          //   label: 'Tipologie evento',
                          //   showArrow: false,
                          // },
                        ],
                      },
                    ]}
                    pinned={[
                      {
                        name: 'me',
                        label: user.first_name + ' ' + user.last_name,
                        icon: 'admin',
                        showArrow: false,
                      },
                      {
                        name: 'logout',
                        label: 'Logout',
                        showArrow: false,
                      },
                    ]}
                    logo={Logo}
                    logoStyles={{
                      width: '80%',
                      paddingLeft: '8%',
                      marginTop: '2vh',
                      marginBottom: '2vh',
                    }}
                    selected={location.pathname.split('/')[1]}
                    color="primary100"
                    selectedColor="primary50"
                    versionColor="gray50"
                    version={version}
                    onSelect={(name) => {
                      if (matches.small) {
                        setSidebarOpen(false)
                      }
                      switch (name) {
                        case 'logout':
                          api.clearSession()
                          history.push('/login')
                          break
                        default:
                          if (dirty) {
                            window.location.href = `/${name}`
                          } else {
                            history.push(`/${name}`)
                          }
                          break
                      }
                    }}
                  />
                </SidebarContainer>
              )}
              {!hideHeader && (
                <HeaderContainer>
                  <Header
                    title={title}
                    backLink={backLink}
                    dirty={dirty}
                    rightWidgets={[
                      ...(headerOutlineButton
                        ? [
                            <Button
                              text={headerOutlineButton.text}
                              outline
                              onClick={() => {
                                if (dirty) {
                                  return (window.location.href = headerOutlineButton.to)
                                }
                                history.push(headerOutlineButton.to)
                              }}
                              size="large"
                              radius={4}
                              style={{ backgroundColor: 'transparent' }}
                            />,
                          ]
                        : []),
                      ...(headerButton
                        ? [
                            <Button
                              text={headerButton.text}
                              onClick={() => {
                                if (dirty) {
                                  return (window.location.href = headerButton.to)
                                }
                                history.push(headerButton.to)
                              }}
                              size="large"
                              radius={4}
                            />,
                          ]
                        : []),
                    ]}
                  ></Header>
                </HeaderContainer>
              )}
              {(!matches.small || !isSidebarOpen) && (
                <ContentContainer hideHeader={hideHeader}>{children}</ContentContainer>
              )}
            </>
          )}
        </Media>
      </SessionContext.Provider>
    </Grid>
  )
}

const Grid = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-template-rows: 160px 1fr;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const SidebarContainer = styled.div`
  width: 260px;
  max-height: 100vh;
  height: 100%;
  grid-area: 1 / 1 / 3 / 2;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  @media screen and (max-width: 600px) {
    min-width: 100vw;
    min-height: 100vh;
    position: fixed;
    max-height: unset;
    grid-area: 1 / 1 / 3 / 2;
  }
`

const HeaderContainer = styled.div`
  background-color: white;
  width: 100%;
  grid-area: 1 / 2 / 2 / 3;
  @media screen and (max-width: 600px) {
    grid-area: 1 / 1 / 2 / 2;
  }
`

const ContentContainer = styled.div<{ hideHeader?: boolean }>`
  background-color: white;
  width: 100%;
  grid-area: ${(props) => (props.hideHeader ? 1 : 2)} / 2 / 3 / 3;
  @media screen and (max-width: 600px) {
    grid-area: ${(props) => (props.hideHeader ? '1 / 1 / 3 / 2' : '2 / 1 / 3 / 2')};
  }
`
