import React from 'react'
import { Select } from '../components/Select'

export const resourceAutoComplete: (setup: {
    name: string,
    emptyMessage: string,
    searchAction: (search: string) => any,
    resultMapper: (result: any) => { value: string, label: string },
}) => React.FC<any> = (setup) => (props) => {
    return (
        <Select
            name={setup.name}
            label=''
            emptyMessage={setup.emptyMessage}
            value={props.data.id}
            onChange={(entry) => props.data.setValue(entry.value)}
            getItemsPromise={(search) =>
                setup.searchAction(search).then((result: any) =>
                    result.items.map(setup.resultMapper)
                )
            }
        />
    )
}
