import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, Input, DateTimePicker, Button } from '@duckma/react-ds'
import it from 'date-fns/locale/it'
import moment from 'moment'

import { Select, SelectEntry } from '../../../components/Select'
import { api, CreateEvent } from '../../../data/api'
import { Period } from '../../../data/models'

const initialEvent: Omit<CreateEvent, 'type'> & { type: SelectEntry | null } = {
  title: '',
  period: {
    from: new Date().toISOString(),
    to: new Date().toISOString(),
  },
  permissions: 'public',
  type: null,
}

export const AddEventModal: React.FC<{
  open: boolean
  period: Period | null
  onClose: () => void
  onCreate: (data: CreateEvent) => void
}> = ({ open, period, onClose, onCreate }) => {
  const [event, setEvent] = useState(initialEvent)

  useEffect(() => {
    setEvent({
      ...initialEvent,
      period: period || {
        from: new Date().toISOString(),
        to: new Date().toISOString(),
      },
    })
  }, [open, period])

  const validTitle = event.title.length > 0
  const validType = event.type !== null
  const validDates = moment(event.period.from).isBefore(event.period.to)

  return (
    <StyledModal open={open} onClose={onClose} title="Nuovo Evento" color="white">
      <Content
        autoComplete="off"
        onSubmit={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()
          if (!event.type) {
            return
          }

          onCreate({
            ...event,
            type: event.type.value,
          })
        }}
      >
        {event && (
          <FormGrid>
            <Input
              autocomplete="off"
              type="text"
              name="note"
              value={event.title}
              onChange={(title) => setEvent({ ...event, title })}
              valid={validTitle}
              errorText="Campo obbligatorio"
            />
            <Select
              name="tipologia"
              label="Tipologia"
              placeholder="Scegli una tipologia di evento"
              emptyMessage="Nessuna tipologia trovata"
              value={event.type}
              onChange={(type) => setEvent({ ...event, type })}
              getItemsPromise={(search) =>
                api
                  .getEventTypesByTitle(search)
                  .then((result) =>
                    result.items.map(({ id, name }) => ({ value: id, label: name }))
                  )
              }
              valid={validType}
              errorText="Campo obbligatorio"
            />
            <DateTimePicker
              name="data-e-ora-di-inizio"
              locale={it}
              selected={new Date(event.period.from)}
              showTimeSelect={event.type?.label === 'Permessi'}
              onChange={(from) =>
                setEvent({ ...event, period: { ...event.period, from: from.toISOString() } })
              }
              valid={validDates}
              errorText="La data di inizio è dopo la data di fine"
            />
            <DateTimePicker
              locale={it}
              name="data-e-ora-di-fine"
              selected={new Date(event.period.to)}
              showTimeSelect={event.type?.label === 'Permessi'}
              onChange={(to) =>
                setEvent({ ...event, period: { ...event.period, to: to.toISOString() } })
              }
            />
          </FormGrid>
        )}
        <ButtonFooter>
          <div />
          <Button
            text="Crea evento"
            color="primary"
            radius={4}
            disabled={[validTitle, validType, validDates].some((x) => !x)}
          />
        </ButtonFooter>
      </Content>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  position: fixed;
  top: 15vh;
  left: 20vw;
  right: 20vw;
  bottom: 15vh;
  outline: none;
  border-radius: 10px;
  > * {
    z-index: 2;
  }
`

const Content = styled.form`
  box-sizing: border-box;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
  height: calc(100% - 64px);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
`

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  align-items: flex-start;
  width: 100%;
`

const ButtonFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  > :nth-child(1) {
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }
  > :nth-child(n + 2) {
    flex-grow: 0;
    flex-basis: 35%;
  }
`
