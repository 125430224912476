import React from 'react'
import styled from 'styled-components'
import { Column } from 'devextreme-react/data-grid'

import { CustomDataGrid } from '../../components/CustomDataGrid'
import { api } from '../../data/api'

export const CompaniesPage = () => (
  <Container>
    <CustomDataGrid
      exportName="Aziende"
      onLoad={api.getCompanies}
      onUpdate={api.updateCompany}
      onRemove={api.deleteCompany}
    >
      <Column dataField="code" caption="Nome macchina" dataType="string" />
      <Column dataField="max_employees" caption="N° Massimo Operai" dataType="string" />
    </CustomDataGrid>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`
