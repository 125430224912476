import { Column, GroupItem, Lookup, Summary, TotalItem } from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { CustomDataGrid } from '../../components/CustomDataGrid'

import { api, ListParams } from '../../data/api'
import { EditDuration } from '../../utils/durationEditor'
import { dateFormatter, durationFormatter, ellipse } from '../../utils/formatters'
import { ExportModal } from '../logs/components/ExportModal'
import { useRequest } from '../../hooks/useRequest'
import { LoadOptions } from 'devextreme/data/load_options'

const userDataSource = {
  store: new CustomStore({
    key: 'id',
    load: async (loadOptions: LoadOptions) => {
      const { searchValue } = loadOptions;
      return api.getUsers({ search_text: searchValue }).then(({ items, pagination }) => ({
        data: items,
        totalCount: pagination.total_items,
        summary: pagination.total_pages,
        groupCount: pagination.page_size,
      }))
    },
    byKey: (key: string) => api.getUser({ id: key }),
  }),
}

const machineDataSource = {
  store: new CustomStore({
    key: 'id',
    load: async (loadOptions: unknown) =>
      api.getMachines().then(({ items, pagination }) => ({
        data: items,
        totalCount: pagination.total_items,
        summary: pagination.total_pages,
        groupCount: pagination.page_size,
      })),
    byKey: (key: string) => api.getMachine({ id: key }),
  }),
}

export const MachineLogsPage = () => {
  const [isExportOpen, setExportOpen] = useState(false)
  const [fetchSums, { data: sums }] = useRequest(api.fetchMachineLogsSums);
  const [params, setParams] = useState({});

  useMemo(() => {
    fetchSums(params)
  }, [params]);

  return (
    <Container>
      <ExportModal
        open={isExportOpen}
        onClose={() => setExportOpen(false)}
        onExport={async (from: Date, to: Date) => {
          window.open(
            (
              await api.exportMachineLogs(
                {
                  ...params,
                  page_size: Number.MAX_SAFE_INTEGER,
                  date_from: `${from.toISOString().split('T')[0]}T00:00:00.000Z`,
                  date_to: `${to.toISOString().split('T')[0]}T23:59:59.999Z`,
                },
                {
                  'user.first_name': 'Nome Utente',
                  'user.last_name': 'Cognome Utente',
                  'machine.code': 'Codice Macchina',
                  'order.code': 'Codice Ordine',
                  date: 'Data',
                  logged_minutes: 'Ore',
                  notes: 'Note',
                }
              )
            ).url,
            '_blank',
            'noopener,noreferrer'
          )
        }}
      />
      <CustomDataGrid
        exportName="Log ore macchina"
        remoteOperations={{ filtering: true, paging: true, sorting: true, summary: true }}
        onLoad={api.getMachineLogs}
        onListOptions={(_params) => {
          if (JSON.stringify(params) !== JSON.stringify(_params)) {
            setParams(_params ?? {})
          }
        }}
        onUpdate={({ id, body }) => {
          return api.updateMachineLog({
            id,
            body: {
              ...body,
              order_id: body.order?.id,
              user_id: body.user?.id,
              machine_id: body.machine?.id,
              order: undefined,
              user: undefined,
              machine: undefined,
            },
          })
        }}
        onRemove={api.deleteMachineLog}
        onEditorPreparing={(e) => {
          if (e.dataField === 'notes' && e.parentType === 'dataRow') {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged
            e.editorName = 'dxTextArea'
            e.editorOptions.height = 200
            e.editorOptions.onValueChanged = function (args: any) {
              defaultValueChangeHandler(args)
            }
          }
        }}
        customToolbarButtons={[
          {
            text: 'Export CSV',
            onClick: () => setExportOpen(true),
          },
          {
            text: sums ? `Tot. Interni: ${sums?.internal}h` : ``,
            onClick: async () => { },
          },
          {
            text: sums ? `Tot. Esterni: ${sums?.external}h` : ``,
            onClick: async () => { },
          },
        ]}
      >
        <Column dataField="user.id" caption="Utente" dataType="string" allowEditing={false}>
          <Lookup
            dataSource={userDataSource}
            displayExpr={(user: any) => `${user.first_name} ${user.last_name} (${user.email})`}
            valueExpr="id"
          />
        </Column>
        <Column dataField="order.code" caption="Commessa" dataType="string">
        </Column>
        <Column dataField="order.customer" caption="Cliente" dataType="string">
        </Column>
        <Column dataField="machine.id" caption="Macchina" dataType="string">
          <Lookup
            dataSource={machineDataSource}
            displayExpr={(machine: any) => `${machine.code}`}
            valueExpr="id"
          />
        </Column>
        <Column
          dataField="logged_minutes"
          caption="Ore"
          allowSearch={false}
          headerFilter={{ allowSearch: false }}
          dataType="string"
          format={durationFormatter}
          editCellComponent={EditDuration}
        />
        <Column dataField="date" caption="Data" dataType="date" format={dateFormatter} />
        <Column
          dataField="notes"
          caption="Note"
          dataType="string"
          format={ellipse}
          width="20vw"
          allowSearch={false}
          headerFilter={{ allowSearch: false }}
        />
        <Summary
          calculateCustomSummary={(options: any) => {
            switch (options.summaryProcess) {
              case 'start':
                options.totalValue = 0
                break
              case 'calculate':
                options.totalValue += options.value
                break
              case 'finalize':
                options.totalValue = durationFormatter(options.totalValue)
                break
            }
          }}
        >
          <GroupItem column="logged_minutes" summaryType="custom" />
          <TotalItem column="logged_minutes" summaryType="custom" />
        </Summary>
      </CustomDataGrid>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  * > textarea {
    box-sizing: border-box; /* fit parent width */
    height: 200px !important;
    line-height: 1.2 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`
